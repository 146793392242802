.search-pg-ctrl {
	display: grid;
	grid-template-columns: 50vw 50vw;
	height: calc(100vh - 83px);
}

.search-wrap {
	display: flex;
	flex-direction: column;
	z-index: 0;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}

.search-bar-wrap {
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid lightgray;
}

.search-bar {
	display: flex;
	padding: 10px;
	gap: 10px;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
}

.search-bar i {
	cursor: pointer;
}

.search-bar .results {
	font-size: 12px;
	font-weight: lighter;
	background-color: var(--blue2);
	color: white;
	padding: 5px 10px;
	border-radius: 20px;
}

.search-list {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	padding: 20px;
	gap: 10px;
	overflow-x: hidden;
	white-space: nowrap;
	height: calc(100vh - 215px);
}

.search-bar .filters {
	color: gray;
}

.search-bar .sh-label {
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 12px;
	color: var(--blue);
}

.search-bar .wrap {
	display: flex;
	align-items: center;
	gap: 5px;
	border: 1px solid var(--blue);
	padding: 5px;
	border-radius: 5px;
	font-size: 14px;
}

.wrap select {
	outline: none;
	border: none;
	font-size: 14p;
}

/* No search results */
.search-no-results {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	gap: 10px;
}

.search-no-results .img {
	width: 300px;
}

.search-no-results .title {
	font-size: 28px;
	font-family: "Times New Roman", Times, serif;
	font-weight: bold;
}

.search-no-results .desc {
	font-weight: lighter;
}

@media screen and (max-width: 1024px) {
	.search-list {
		grid-template-columns: 1fr;
	}
}

@media screen and (max-width: 650px) {
	.search-pg-ctrl {
		grid-template-columns: 1fr;
	}

	.search-bar-wrap {
		display: flex;
		flex-direction: column;
	}
}
