.review-box {
	display: flex;
	flex-direction: column;
	gap: 10px;
	border-top: 1px solid gray;
	padding: 20px 0px;
}

.reveiew-char {
	font-size: 14px;
	font-weight: lighter;
	color: var(--error);
}

.review-rating {
	display: flex;
	align-items: flex-end;
	gap: 10px;
}

.review .edit-wrap i {
	cursor: pointer;
}

.review .review-star {
	height: 30px;
	display: flex;
	align-items: center;
}

/* Edit Review */
.review .edit-textarea-wrap {
	display: flex;
	flex-direction: column;
}

.review .btn-wrap {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	gap: 30px;
}

.review .btn-group {
	display: flex;
	flex-direction: row;
	gap: 30px;
}

/* Delete Review */
.review-delete {
	background-color: white;
	padding: 40px;
	border-radius: 10px;
	width: 600px;
	max-height: 90vh;
	display: flex;
	flex-direction: column;
	gap: 20px;
	overflow-x: hidden;
	white-space: nowrap;
}

.review-delete .title {
	font-size: 24px;
	font-weight: bold;
}

.review-delete .content {
	font-size: 18px;
	line-height: 24px;
	font-weight: lighter;
	padding: 20px;
	background-color: var(--lightblue);
	border-radius: 10px;
	border: 1px solid var(--blue);
	white-space: normal;
}

.review-delete .btn-wrap {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 20px;
}

/* Review Modal */
.review-ctrl {
	padding: 40px;
	background-color: white;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
	width: 500px;
	min-height: fit-content;
	max-height: 90vh;
	overflow-x: hidden;
	white-space: nowrap;
}

.review-ctrl .for {
	font-size: 20px;
	font-weight: bold;
}

.review-ctrl .photo {
	width: 120px;
	height: 120px;
	border-radius: 100%;
	background-color: var(--lightblue);
	background-position: center;
	background-size: cover;
}

.review-ctrl .agent-name {
	font-size: 26px;
	font-family: "Times New Roman", Times, serif;
	font-weight: bold;
}

.review-ctrl .stars-wrap,
.review-ctrl .content-wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 5px;
	width: 100%;
}

.review-ctrl .stars-how,
.review-ctrl .content-how {
	font-size: 14px;
	font-weight: lighter;
}

.content-wrap .textarea {
	padding: 20px;
	background-color: var(--lightblue);
	border-radius: 10px;
	border: 1px solid var(--blue2);
}

.review-ctrl .errors {
	font-size: 12px;
	font-weight: lighter;
	color: var(--error);
}
.review-ctrl .btn-wrap {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 20px;
}

/* Agent review page */
.agent-rvw-ctrl {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 40px;
	gap: 40px;
}

.agent-rvw-stars-wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	font-size: 24px;
}
