@import url("./css/navbar.css");
@import url("./css/input.css");
@import url("./css/splash.css");
@import url("./css/footer.css");
@import url("./css/error.css");
@import url("./css/searchList.css");
@import url("./css/propertyCard.css");
@import url("./css/modal.css");
@import url("./css/property.css");
@import url("./css/button.css");
@import url("./css/tour.css");
@import url("./css/loginSignup.css");
@import url("./css/notification.css");
@import url("./css/appointment.css");
@import url("./css/stars.css");
@import url("./css/review.css");
@import url("./css/calendar.css");
@import url("./css/agents.css");
@import url("./css/agent.css");
@import url("./css/about.css");
@import url("./css/map.css");
@import url("./css/not-found.css");
@import url("./css/profile.css");
@import url("./css/chat.css");

:root {
	--blue: #016bff;
	--blue2: #0346a4;
	--lightblue: #f0f9ff;
	--error: rgb(172, 12, 12);
	--lightgray: rgb(242, 242, 242);
	--lightbg: #f7f7fa;
	--lightbg2: #eeedf2;
}

* {
	box-sizing: border-box;
	font-family: sans-serif;
}

a {
	text-decoration: none;
	color: unset;
}

ul,
li {
	list-style: none;
}

body {
	margin: 0;
	padding: 0;
}

textarea:focus,
input:focus {
	outline: none;
}

.hidden {
	display: none;
	opacity: 0;
	visibility: hidden;
}

.gap40 {
	gap: 40px;
}

.gap15 {
	display: flex;
	flex-direction: column;
	gap: 15px;
}
