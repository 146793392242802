.card-ctrl {
	height: 280px;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
	border-bottom: 5px solid var(--blue);
	cursor: pointer;
}

.card-top {
	height: 150px;
	background-position: center;
	background-size: cover;
	display: flex;
	justify-content: space-between;
}

.card-events {
	padding: 7px 20px;
	background-color: rgba(0, 0, 0, 0.5);
	height: fit-content;
	color: white;
	font-size: 15px;
}

.card-btm {
	padding: 10px;
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.card-price {
	font-size: 24px;
}

.card-desc {
	font-size: 16px;
	font-weight: lighter;
	padding: 3px 0px;
}

.card-address {
	font-size: 14px;
	white-space: normal;
}

.card-office {
	font-size: 12px;
	font-weight: lighter;
	color: gray;
}
