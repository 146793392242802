.agent-ctrl {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5%;
        width: 90%;
        gap: 40px;
}

.agent-ctrl .split {
        display: grid;
        grid-template-columns: auto;
        width: 100%;
        gap: 40px;
}

.agent-ctrl .center {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

.agent-ctrl .photo {
	width: 300px;
	height: 300px;
	border-radius: 100%;
	background-color: var(--lightblue);
	background-size: cover;
	background-position: center center;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	font-weight: bold;
	color: var(--blue2);
	position: relative;
}

.agent-ctrl .name {
	font-size: 40px;
	font-family: "Times New Roman", Times, serif;
	font-weight: bold;
	text-align: center;
}

.agent-ctrl .office {
	color: gray;
	font-size: 22px;
	font-weight: lighter;
	text-align: center;
}

.agent-ctrl .license {
	text-align: center;
}

.agent-ctrl .bio-wrap {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 40px;
	width: 100%;
}

.bio-wrap .about {
	font-size: 26px;
	font-weight: bold;
	color: var(--blue2);
}

.bio-wrap .bio {
	font-weight: lighter;
	font-size: 18px;
	line-height: 26px;
}

.bio-wrap .gap5 {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.bio-wrap .phone {
	font-size: 20px;
	font-weight: lighter;
}

.agent-review-ctrl {
	display: flex;
	flex-direction: column;
	box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
	width: 100%;
}

.agent-review-ctrl .title {
	font-size: 26px;
	font-weight: bold;
	padding: 20px;
	border-bottom: 1px solid gray;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.agent-review-ctrl .review {
	padding: 30px;
	font-size: 18px;
	font-weight: lighter;
	line-height: 24px;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.agent-review-ctrl .review:not(:first-child) {
	border-top: 1px solid gray;
}

.review .review-edit {
	display: flex;
	justify-content: space-between;
}

.review .edit-wrap {
	display: flex;
	gap: 10px;
	color: var(--blue);
}

.agent-profile-div {
	position: relative;
}

.agent-sa {
        position: relative;
        background-color: white;
        width: 90%;
        padding: 5%;
        border-radius: 10px;
}

/* Agent 404 */
.agent-404-ctrl {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 85px);
	gap: 30px;
}

.agent-404-ctrl img {
	width: 300px;
}

.agent-404-ctrl .title {
	font-size: 28px;
	font-weight: bold;
}

.agent-404-ctrl .desc {
	font-weight: lighter;
}
