.star-wrap {
	display: flex;
}

.star {
	width: 20px;
	height: 20px;
}

.star-rating button {
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
	padding: 0px;
	font-size: 18px;
}

.star-rating .on {
	color: var(--blue);
}

.star-rating .off {
	color: lightgray;
}

.big-star-wrap {
	background: url("../assets/stars/star-rating-sprite.png") repeat-x;
	font-size: 0;
	height: 75px;
	line-height: 0;
	overflow: hidden;
	text-indent: -999em;
	width: 375px;
	margin: 0 auto;
}

.big-star-sprite {
	background: url("../assets/stars/star-rating-sprite.png") repeat-x;
	background-position: 0 100%;
	float: left;
	height: 75px;
	display: block;
}
