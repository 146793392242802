.splash-ctrl {
	display: flex;
	flex-direction: column;
}

.splash-search-wrap {
	display: flex;
	flex-direction: column;
        align-items: left;
	width: 100vw;
	height: 450px;
	background-image: url(../assets/mh-hero-1440.webp);
	background-size: cover;
	background-position: center;
	gap: 10px;
}

.splash-search-title {
	color: white;
	font-family: "Times New Roman", Times, serif;
	font-size: 40px;
	font-weight: 600;
	display: flex;
        align-items: flex-start;
        width: 80%;
        padding-left: 10%;
}

.splash-search-subtitle {
	color: white;
	font-family: "Open Sans",Gotham,gotham,Tahoma,Geneva,sans-serif,Times,serif;
	font-size: 18px;
	font-weight: 200;
	display: flex;
	align-items: flex-start;
	width: 80%;
	padding-left: 10%;
}

@media screen and (max-width: 767px){
.splash-search-title {
        color: white;
        font-family: "Times New Roman", Times, serif;
        font-size: 30px;
        font-weight: 400;
        display: flex;
        align-items: flex-start;
        width: 80%;
        padding-left: 10%;
}

.splash-search-subtitle {
        color: white;
        font-family: "Open Sans",Gotham,gotham,Tahoma,Geneva,sans-serif,Times,serif;
        font-size: 18px;
        font-weight: 200;
        display: flex;
        align-items: flex-start;
        width: 80%;
        padding-left: 10%;
}
}

.splash-agent-button {
	background-color: var(--blue);
	padding: 10px 20px;
	border-radius: 5px;
	border: none;
	color: white;
	font-size: 16px;
	transition: all 0.25s;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	gap: 5px;
	align-items: center;
	width: fit-content;
}

.search-dd {
	visibility: hidden;
	position: absolute;
	background-color: white;
	top: 75px;
	left: 0px;
	width: 100%;
	max-height: 50vh;
	min-height: fit-content;
	overflow-x: hidden;
	white-space: nowrap;
	border-radius: 5px;
	box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}

.search-dd-sm {
	top: 45px;
}

.search-dd .div {
	padding: 10px;
	display: flex;
	gap: 10px;
	align-items: center;
	border-bottom: 1px solid var(--lightbg);
	cursor: pointer;
}

.search-dd .div:hover {
	background-color: var(--lightblue);
}

.search-dd-term {
	width: 100%;
	display: flex;
	align-items: center;
}

.search-dd .div i {
	color: var(--blue);
}

.search-input:focus ~ .search-dd {
	visibility: visible;
}
