/* User Navbar */

.nav {
	display: grid;
	grid-template-columns: 1fr 160px 1fr;
	padding: 22px;
	border-bottom: 1px solid gray;
	align-items: center;
	position: sticky;
	top: 0px;
	background-color: white;
	z-index: 1;
}

.nav-lf {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 20px;
}

.nav-rt {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 20px;
	position: relative;
}

.nav-rt .photo {
	width: 36px;
	height: 36px;
	border-radius: 100%;
	background-size: cover;
	background-position: center center;
	cursor: pointer;
}

.nav-rt .dropdown {
	position: absolute;
	background-color: white;
	top: 70px;
	right: 0px;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	z-index: 99;
}

.dropdown.demo-login {
	padding: 20px;
	gap: 20px;
}

.nav-rt .dropdown::before {
	content: "";
	position: absolute;
	width: 0px;
	height: 0px;
	border-style: solid;
	border-width: 0px 10px 15px 10px;
	border-color: transparent transparent white transparent;
	display: inline-block;
	vertical-align: middle;
	right: 10px;
	top: -15px;
}

.nav-rt .dropdown .div {
	padding: 20px 20px;
	font-weight: lighter;
	width: 100%;
}

/* Agent Navbar */
.nav-agent {
	background-color: var(--blue2);
	color: white;
	padding: 15px;
}

.nav-agent .btn-font-lt {
	color: white;
}

.nav-agent .btn-font-lt:hover {
	color: var(--lightblue);
}
