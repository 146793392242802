.btn {
	background-color: var(--blue);
	padding: 10px 20px;
	border-radius: 5px;
	border: none;
	color: white;
	font-size: 16px;
	transition: all 0.25s;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	gap: 5px;
	align-items: center;
}

.btn-bl {
	background-color: black;
}

.btn-red {
	background-color: var(--error);
}

.btn-w {
	width: 100%;
}

.btn:hover {
	background-color: var(--blue2);
}

.btn-font {
	color: var(--blue);
	font-weight: bold;
	background-color: transparent;
	border: none;
	cursor: pointer;
}

.btn-font:hover {
	color: var(--blue2);
}

.btn-font-lt {
	color: black;
	font-weight: lighter;
	background-color: transparent;
	border: none;
	cursor: pointer;
	font-size: 16px;
}

.btn-font-lt:hover {
	color: var(--blue2);
}

.btn-desc {
	font-size: 14px;
	font-weight: lighter;
	color: var(--lightblue);
}

.btn-wrap-rt {
	display: flex;
	justify-content: flex-end;
	gap: 20px;
}

.btn-wrap {
	display: flex;
	gap: 20px;
}

.btn-sm {
	padding: 5px;
	font-size: 12px;
}

.btn-gr {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 10px;
	padding: 5px;
	font-size: 18px;
	background-color: green;
	border: transparent;
	border-radius: 5px;
	color: white;
	cursor: pointer;
}

.btn-short {
	width: fit-content;
	padding: 5px 10px;
}
