/* Agent profile */
.service-area-btn-wrap {
	display: flex;
	gap: 10px;
}

.agent-profile-photo,
.agent-profile-no-photo {
	width: 300px;
	height: 300px;
	border-radius: 100%;
	background-color: var(--lightblue);
	background-size: cover;
	background-position: center center;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	font-weight: bold;
	color: transparent;
	position: relative;
	/* cursor: pointer; */
	transition: all 0.5s;
}

.agent-profile-no-photo {
	border: 5px dashed lightgray;
	color: var(--blue2);
}

/*
.agent-profile-photo:hover {
	color: white;
	background-color: rgba(0, 0, 0, 0.5);
	background-blend-mode: multiply;
} */

.upload-photo-div {
	position: absolute;
	right: 20px;
	top: 20px;
	background-color: white;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
	display: flex;
	justify-content: center;
	align-items: center;
}

.profile-upload-preview {
	position: absolute;
	right: 0px;
	bottom: 0px;
	width: 0px;
	height: 0px;
	border-radius: 100%;
	object-fit: cover;
}

.wrap-ctr {
	width: 100%;
	display: flex;
	justify-content: center;
}

.agent-profile {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.agent-profile .about {
	font-size: 26px;
	font-weight: bold;
	color: var(--blue2);
}

/* User profile */
.user-profile-modal {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
	background-color: white;
	padding: 40px;
	border-radius: 10px;
	max-height: 90vh;
	overflow-x: hidden;
	white-space: nowrap;
}

.user-profile-modal .title {
	font-family: "Times New Roman", Times, serif;
	font-weight: bold;
	font-size: 36px;
}

.user-profile-modal .photo {
	width: 300px;
	height: 300px;
	border-radius: 100%;
	background-size: cover;
}
