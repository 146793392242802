.about-ctrl {
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 83px);
	background-color: var(--lightblue);
	background-position: center bottom;
	background-repeat: no-repeat;
	background-size: contain;
}

.about-ctrl .wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: white;
	padding: 40px;
	border-radius: 10px;
	box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
	gap: 30px;
}

.about-ctrl .img {
	width: 250px;
	height: 250px;
}

.about-ctrl .name {
	font-family: "Times New Roman", Times, serif;
	font-size: 24px;
	font-weight: bold;
	text-align: center;
}
.about-ctrl .slogan {
	text-align: center;
	font-weight: lighter;
	line-height: 24px;
}

.about-ctrl .icon-group {
	display: flex;
	gap: 20px;
}

.about-ctrl .icon {
	width: 30px;
	height: 30px;
	font-size: 30px;
}
